import SearchFormFnac from '../views/fnac/SearchForm.vue'
import BasketInfoFnac from '../views/fnac/Basket.vue'
import Error from '../views/fnac/Error.vue'
import FinalizeBasket from '../views/fnac/FinalizeBasket.vue'
import OrderCancelFnac from '../views/fnac/OrderCancel.vue'
import OrderFinalizeFnac from '../views/fnac/FinalizeOrder.vue'
import CardFormFnac from '../views/fnac/CardForm.vue'
import KycFnac from '../views/fnac/Kyc.vue'
import LoginFnac from '../views/fnac/Login.vue'
import TransferFnac from '../views/fnac/Transfer.vue'
import ValidatedFnac from '../views/fnac/Validated.vue'
import RequestAccess from '../views/fnac/RequestAccess.vue'
import accessGuard from './guards/access'

export default [
    {
        path: '/iframe/searchForm',
        name: 'SearchFormFnac',
        component: SearchFormFnac,
        beforeEnter: accessGuard.hasAccess
    },
    {
        path: '/iframe/basket/:id',
        name: 'basketInfoFnac',
        component: BasketInfoFnac
    },
    {
        path: '/iframe/error',
        name: 'error',
        component: Error
    },
    {
        path: '/iframe/basket/:id/finalizeBasket',
        name: 'finalizeBasket',
        component: FinalizeBasket
    },
    {
        path: '/iframe/order/:id/cancelOrder',
        name: 'orderCancelFnac',
        component: OrderCancelFnac
    },
    {
        path: '/iframe/basket/:id/finalizeOrder',
        name: 'orderFinalizeFnac',
        component: OrderFinalizeFnac
    },
    {
        path: '/iframe/order/:id/cardForm',
        name: 'cardFormFnac',
        component: CardFormFnac
    },
    {
        path: '/iframe/order/:id/kyc',
        name: 'kycFnac',
        component: KycFnac
    },
    {
        path: '/iframe/order/:id/login',
        name: 'loginFnac',
        component: LoginFnac
    },
    {
        path: '/iframe/order/:id/transfer',
        name: 'transferFnac',
        component: TransferFnac
    },
    {
        path: '/iframe/order/:id/validated',
        name: 'validatedFnac',
        component: ValidatedFnac
    },
    {
        path: '/iframe/requestAccess',
        name: 'requestAccess',
        component: RequestAccess
    },
]