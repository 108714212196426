export default {

    hasAccess(from, to, next) {

        let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
        if (!document.hasStorageAccess || !isSafari) {

            next()
        }
        else {

            console.log('requestHasAccess init middleware')
            document.hasStorageAccess()
            .then(result => {
                
                console.log('requestHasAccess result', result)
                if (result) {

                    next()
                }
                else {

                    next({name: 'requestAccess', query: {r: from.fullPath}})
                }
            })
            .catch(error => {

                console.log('requestHasAccess error', error)
                next({name: 'requestAccess', query: {r: from.fullPath}})
            })
        }
    }
}